$primary: #FF6B6B;
$secondary: #467B9D;
$success: #44a728;
$dark: darken($secondary, 30%);
$light: #f2f2f4;

$theme-colors: (
        primary: $primary,
        secondary: $secondary,
        success: $success,
        dark: $dark,
        light: $light,
);

$font-family-base: 'Tajawal', sans-serif;

//$font-size-base: 16;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

$vh-sizes: (100, 75, 50, 25, 60);

@each $s in $vh-sizes {
  @each $b, $v in $grid-breakpoints {
    @include media-breakpoint-up($b) {
      .min-vh-#{$b}-#{$s} {
        min-height: 1vh * $s !important;
      }
    }
  }
  .min-vh-#{$s} {
    min-height: 1vh * $s;
  }
}

.logo-font {
  //text-shadow: -1px 1px 0 $secondary,
  //1px 1px 0 $secondary,
  //1px -1px 0 $secondary,
  //-1px -1px 0 $secondary;
  color: $secondary;
}

.has-fill-parent {
  position: relative;

  > .fill-parent, .fill-parent-bg {
    position: relative;
    height: 100%;
    width: 100%;
  }

  > .fill-parent-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

    & ~ * {
      position: relative;
      z-index: 1;
    }
  }
}

#navbar {
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out, background-color 0.2s ease-out;

  &.hide {
    transform: translateY(-100%);
    @extend .shadow-none;
  }
  .navbar-brand img {
    transition: height 0.2s ease-out;
  }

  .navbar-nav {
    transition: font-size 0.2s ease-out;
    font-weight: bold;
  }

  &:not(.on-top) {
    @extend .bg-light;
    @extend .shadow;
  }

  &.on-top {
    @extend .bg-transparent;

    .navbar-brand img {
      height: 40vh;
      min-height: 200px;
      max-height: 500px;
      display: block;
    }

    .navbar-nav {
      font-size: 1.4em;
    }
  }
}

#header {
  h1 {
    font-size: 4em;
  }

  h3 {
    font-size: 2em;
  }
}

.header-bg-image {
  background-color: #f7f7f7;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Cg %3E%3Cpolygon fill='%2331566e' points='100 57.1 64 93.1 71.5 100.6 100 72.1'/%3E%3Cpolygon fill='%233c6985' points='100 57.1 100 72.1 128.6 100.6 136.1 93.1'/%3E%3Cpolygon fill='%2331566e' points='100 163.2 100 178.2 170.7 107.5 170.8 92.4'/%3E%3Cpolygon fill='%233c6985' points='100 163.2 29.2 92.5 29.2 107.5 100 178.2'/%3E%3Cpath fill='%23467b9d' d='M100 21.8L29.2 92.5l70.7 70.7l70.7-70.7L100 21.8z M100 127.9L64.6 92.5L100 57.1l35.4 35.4L100 127.9z'/%3E%3Cpolygon fill='%23b34b4b' points='0 157.1 0 172.1 28.6 200.6 36.1 193.1'/%3E%3Cpolygon fill='%23d95b5b' points='70.7 200 70.8 192.4 63.2 200'/%3E%3Cpolygon fill='%23ff6b6b' points='27.8 200 63.2 200 70.7 192.5 0 121.8 0 157.2 35.3 192.5'/%3E%3Cpolygon fill='%23d95b5b' points='200 157.1 164 193.1 171.5 200.6 200 172.1'/%3E%3Cpolygon fill='%23b34b4b' points='136.7 200 129.2 192.5 129.2 200'/%3E%3Cpolygon fill='%23ff6b6b' points='172.1 200 164.6 192.5 200 157.1 200 157.2 200 121.8 200 121.8 129.2 192.5 136.7 200'/%3E%3Cpolygon fill='%23b34b4b' points='129.2 0 129.2 7.5 200 78.2 200 63.2 136.7 0'/%3E%3Cpolygon fill='%23ff6b6b' points='200 27.8 200 27.9 172.1 0 136.7 0 200 63.2 200 63.2'/%3E%3Cpolygon fill='%23d95b5b' points='63.2 0 0 63.2 0 78.2 70.7 7.5 70.7 0'/%3E%3Cpolygon fill='%23ff6b6b' points='0 63.2 63.2 0 27.8 0 0 27.8'/%3E%3C/g%3E%3C/svg%3E"); //background-size: cover;
  background-repeat: repeat;
  filter: opacity(0.1);
  //background-position: center center;
}

.services-bg-pattern {
  // https://www.svgbackgrounds.com/#bullseye-gradient
  background-color: #467b9d;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%233c6985' fill-opacity='0.18'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.18'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
  height: 200% !important;
}

.phone .notch-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
}

.phone .notch-container .notch {
  width: 40%;
  height: 20%;
  margin: 0 auto;
  background-color: $secondary;
  border-bottom-left-radius: 2.334vh;
  border-bottom-right-radius: 2.334vh;
}

.phone {
  position: relative;
}

.phone {
  display: block;
  border: 1.334vh solid $secondary;
  border-radius: 4.5vh;
}
